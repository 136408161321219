import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PlanCard from './PlanCard';
import Slider from 'react-slick';
import { AnimationOnScroll } from "react-animation-on-scroll";
import './PaymentPlan.scss'

const plansData = [
	{
		percent: '5',
		details: 'Up front as a reservation fee'
	},
	{
		percent: '15',
		details: 'To be paid within 6 months of initial payment'
	},
	{
		percent: '20',
		details: 'To be paid 3 months after 15% payment'
	},
	{
		percent: '60',
		details: 'On completion of project'
	},
]


const PaymentPlan = () => {
	let settings = {
		dots: true,
		arrows: false,
		infinite: false,
		speed: 500,
		mobileFirst: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		responsive: [
		  {
			breakpoint: 767,
			settings: {
			  slidesToShow: 1,
			  slidesToScroll: 1,
			},
		  },
		  {
			breakpoint: 1199,
			settings: {
			  slidesToShow: 2,
			  slidesToScroll: 1,
			},
		  },
		  {
			breakpoint: 3000,
			settings: {
			  slidesToShow: 4,
			  slidesToScroll: 1,
			},
		  },
		],
	};
	return (
		<section className='payment-plans'>
 			<Container>
 				<Row>
				 <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">

 					<Col>
					 	<h3>Payment Plan</h3>
						<p>Lorem ipsum dolor sit amet consectetur adipiscing elit ut dignissim felis po.</p>
 						<div className="plans-wrap">
							<Slider {...settings} className='btn-fix'>
 								{plansData.map((plan, index) => <PlanCard key={index} planData={plan}/>)}
							</Slider>
 						</div>
 					</Col>
					 </AnimationOnScroll>
 				</Row>
 			</Container>
 		</section>
	)
}

export default PaymentPlan