import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import "./PlanBanner.scss";
import bannerMob from "../../images/offPlanDetails/plan-banner-376.jpg";
import bannerTab from "../../images/offPlanDetails/plan-banner-768.jpg";
import bannerDesk from "../../images/offPlanDetails/plan-banner-1400.jpg";
import bannerDeskLg from "../../images/offPlanDetails/plan-banner-1800.jpg";
import ButtonGroup from "../ButtonGroup/ButtonGroup";
import { AnimationOnScroll } from "react-animation-on-scroll";
const PlanBanner = () => {
  return (
    <section className="plan-banner">
      <div className="breadcrumbs-wrap">
        <Container>
          <Row>
            <Col>
              <div className="content-wrap">
                <Link to="/" className="back-link">
                  Back to Listings
                </Link>
                <Breadcrumb>
                  <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                  <Breadcrumb.Item href="#">
                    Off Plan Developments in Dubai
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    Peninsula by Select Group
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="banner-wrap">
        <div className="img-wrap">
          <picture>
            <source media="(min-width: 1600px)" srcSet={bannerDeskLg} />
            <source media="(min-width: 1200px)" srcSet={bannerDesk} />
            <source media="(min-width: 768px)" srcSet={bannerTab} />
            <img src={bannerMob} alt="banner" />
          </picture>
        </div>
        <Container>
          <Row>
            <Col>
              <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
                <h1>Peninsula by Select Group</h1>
                <p className="md">
                  Peninsula 5 is strategically positioned and thoughtfully
                  designed in the heart of Business Bay &mdash; the Central
                  Business District of Dubai (CBD).
                </p>
                <p className="td">
                  Etiam cursus augue ac maximus maximus. Phasellus lobortis ante
                  at erat fringilla, viverra pulvinar augue dictum quisque
                  molestie lacinia lectus.
                </p>
                <ButtonGroup />
              </AnimationOnScroll>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default PlanBanner;
