import React from 'react';
import './PlanCard.scss'

const PlanCard = ({planData}) => {
	return (
		<div className='plan-card'>
			<h2>{`${planData.percent}%`}</h2>
			<p>{planData.details}</p>
		</div>
	)
}

export default PlanCard