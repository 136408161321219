import React from 'react';
import {Tabs, Tab} from 'react-bootstrap';
import './PlanTabs.scss';
import DetailsTab from '../DetailsTab/DetailsTab';
import { useWindowSize } from '../../../hooks/window-size';

const PlanTabs = () => {
	const[windowWidth] = useWindowSize();
	return (
		<div className='plan-tabs'>
			{windowWidth < 1200 && <div className="more-btn">
				<button type='button'>+2 more</button>	
			</div>}
			<Tabs defaultActiveKey="details & description" id="uncontrolled-tab-example" className="mb-3">
				<Tab eventKey="details & description" title="details & description">
					<DetailsTab />
				</Tab>
				<Tab eventKey="brochure" title="brochure">
					<DetailsTab />
				</Tab>
				<Tab eventKey="floor plans" title="floor plans">
					<>No data</>
				</Tab>
				<Tab eventKey="payment plan" title="payment plan">
				<>No data</>
				</Tab>
				<Tab eventKey="video" title="video">
				<>No data</>
				</Tab>
				<Tab eventKey="gallery" title="gallery">
				<>No data</>
				</Tab>
				<Tab eventKey="location" title="location">
				<>No data</>
				</Tab>
			</Tabs>
		</div>
	)
}

export default PlanTabs