import React from 'react';
import './OffPlanDetails.scss';
import Header from '../../components/Header/Header';
import PlanBanner from '../../components/PlanBanner/PlanBanner';
import PlanDetails from '../../components/PlanDetails/PlanDetails';
import VideoBox from '../../components/VideoBox/VideoBox';
import videoThumbnaiMob from '../../images/offPlanDetails/detail-video-thumb-376.jpg'
import videoThumbnaiTab from '../../images/offPlanDetails/detail-video-thumb-768.jpg'
import videoThumbnaiDesk from '../../images/offPlanDetails/detail-video-thumb-1400.jpg'
import videoThumbnaiDeskLg from '../../images/offPlanDetails/detail-video-thumb-1800.jpg'
import PaymentPlan from '../../components/PaymentPlan/PaymentPlan';
import ImageGallery from '../../components/ImageGallery/ImageGallery';
import ButtonGroup from '../../components/BottomBtn/Btn';
import Footer from '../../components/Footer/Footer';
import { useWindowSize } from '../../hooks/window-size';

const imgUrls = {
	mob: videoThumbnaiMob,
	tab: videoThumbnaiTab,
	desktop: videoThumbnaiDesk,
	desktopLg: videoThumbnaiDeskLg
}
const OffPlanDetails = () => {
	const [windowWidth] = useWindowSize();

	return (
		<div className="off-plan-details">
			<Header additionalClass="white"/>
			<PlanBanner />
			<PlanDetails />
			<VideoBox imgUrls={imgUrls}/>
			<PaymentPlan />
			<ImageGallery />
			{windowWidth < 768 ? <section className='cta-btns'><ButtonGroup /></section> : null}
			<Footer />
		</div>
	)
}

export default OffPlanDetails