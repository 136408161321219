import React from "react";
import { useWindowSize } from "../../hooks/window-size";
import { Container, Row, Col } from "react-bootstrap";
import DetailsTab from "./DetailsTab/DetailsTab";
import PlanTabs from "./PlanTabs/PlanTabs";
import { AnimationOnScroll } from "react-animation-on-scroll";

import "./PlanDetails.scss";

const PlanDetails = () => {
  const [windowWidth] = useWindowSize();
  return (
    <section className="plan-details">
      <Container>
        <Row>
          <Col>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
              {windowWidth < 768 && <DetailsTab />}
              {windowWidth >= 768 && <PlanTabs />}
            </AnimationOnScroll>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PlanDetails;
