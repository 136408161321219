import React from 'react';
import { Container, Row, Col, Accordion } from 'react-bootstrap';
import { Link } from 'gatsby';
import './DetailsTab.scss'

const detailTabContent = {
	location: 'Business Bay',
	developer: 'Select Group',
	developmentType: 'Waterfront Community',
	completionDate: 'December 2024',
	price: 'AED 639,000',
	propertyDetails: 'Peninsula will stand out as the most unique waterfront destination strategically located in the heart of Business Bay — the Central Business District of Dubai (CBD). The modern development is a perfect example of affordable luxury in Dubai and will be located by the Canal. Business Bay is one of the most desirable communities for property investors due to its central location, great city access, and high potential rental returns. Easy 4 step payment plan available!'
}

const DetailsTab = () => {
	return (
		<div className='details-tab'>
			<ul>
				<li>
					<label>Location:</label>
					<span className='detail-wrap'>{detailTabContent.location}</span>
				</li>
				<li>
					<label>Developer:</label>
					<span className='detail-wrap'>{detailTabContent.developer}</span>
				</li>
				<li>
					<label>Development Type:</label>
					<span className='detail-wrap'>{detailTabContent.developmentType}</span>
				</li>
				<li>
					<label>Completion Date:</label>
					<span className='detail-wrap'>{detailTabContent.completionDate}</span>
				</li>
				<li>
					<label>Prices From:</label>
					<span className='detail-wrap'>{detailTabContent.price.toLocaleString()}</span>
				</li>
			</ul>
			<div className="right-block">
				<p>{detailTabContent.propertyDetails}</p>
				<Accordion>
					<Accordion.Item eventKey="0">
						<Accordion.Header>About this Development</Accordion.Header>
						<Accordion.Body>
							no data
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="1">
						<Accordion.Header>About the Developer</Accordion.Header>
						<Accordion.Body>
							no data
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="2">
						<Accordion.Header>Completion Details</Accordion.Header>
						<Accordion.Body>
							no data
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
				<div className="btns-wrap">
					<Link to='/' className='btn'>Brochure <i className="icon-right"></i></Link>
					<Link to='/' className='btn'>Floorplans <i className="icon-right"></i></Link>
				</div>
			</div>
		</div>
	)
}

export default DetailsTab