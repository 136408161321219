import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { useWindowSize } from "../../hooks/window-size";
import { Container, Row, Col } from "react-bootstrap";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Slider from "react-slick";
import "./ImageGallery.scss";
import galleryImgMob from "../../images/offPlanDetails/gallery-img-1-376.jpg";
import galleryImgTab1 from "../../images/offPlanDetails/gallery-img-1-768.jpg";
import galleryImgTab2 from "../../images/offPlanDetails/gallery-img-2-768.jpg";
import galleryImgTab3 from "../../images/offPlanDetails/gallery-img-3-768.jpg";
import galleryImgTab4 from "../../images/offPlanDetails/gallery-img-4-768.jpg";
import galleryImgDesk1 from "../../images/offPlanDetails/gallery-img-1-1400.jpg";
import galleryImgDesk2 from "../../images/offPlanDetails/gallery-img-2-1400.jpg";
import galleryImgDesk3 from "../../images/offPlanDetails/gallery-img-3-1400.jpg";
import galleryImgDesk4 from "../../images/offPlanDetails/gallery-img-4-1400.jpg";
import galleryImgDesk5 from "../../images/offPlanDetails/gallery-img-5-1400.jpg";
import galleryImgDesk6 from "../../images/offPlanDetails/gallery-img-6-1400.jpg";
import galleryImgDesk7 from "../../images/offPlanDetails/gallery-img-7-1400.jpg";
import galleryImgDesk8 from "../../images/offPlanDetails/gallery-img-8-1400.jpg";
import galleryImgDeskLg1 from "../../images/offPlanDetails/gallery-img-1-1800.jpg";
import galleryImgDeskLg2 from "../../images/offPlanDetails/gallery-img-2-1800.jpg";
import galleryImgDeskLg3 from "../../images/offPlanDetails/gallery-img-3-1800.jpg";
import galleryImgDeskLg4 from "../../images/offPlanDetails/gallery-img-4-1800.jpg";
import galleryImgDeskLg5 from "../../images/offPlanDetails/gallery-img-5-1800.jpg";
import galleryImgDeskLg6 from "../../images/offPlanDetails/gallery-img-6-1800.jpg";
import galleryImgDeskLg7 from "../../images/offPlanDetails/gallery-img-7-1800.jpg";
import galleryImgDeskLg8 from "../../images/offPlanDetails/gallery-img-8-1800.jpg";

const imgUrls = {
  tabImages: [galleryImgTab1, galleryImgTab2, galleryImgTab3, galleryImgTab4],
  desktopImages: [
    galleryImgDesk1,
    galleryImgDesk2,
    galleryImgDesk3,
    galleryImgDesk4,
    galleryImgDesk5,
    galleryImgDesk6,
    galleryImgDesk7,
    galleryImgDesk8,
  ],
  lgDesktopImages: [
    galleryImgDeskLg1,
    galleryImgDeskLg2,
    galleryImgDeskLg3,
    galleryImgDeskLg4,
    galleryImgDeskLg5,
    galleryImgDeskLg6,
    galleryImgDeskLg7,
    galleryImgDeskLg8,
  ],
};

const settings = {
  dots: true,
  arrows: false,
  infinite: false,
  speed: 500,
  mobileFirst: true,
  slidesToShow: 1,
  slidesToScroll: 1,
};
const ImageGallery = () => {
  const [images, setImages] = useState([]);
  const [windowWidth] = useWindowSize();

  useEffect(() => {
    if (windowWidth > 767 && windowWidth < 1200) {
      return setImages(imgUrls.tabImages);
    }
    if (windowWidth >= 1200 && windowWidth < 1600) {
      return setImages(imgUrls.desktopImages);
    }
    if (windowWidth >= 1600) {
      return setImages(imgUrls.lgDesktopImages);
    }
  }, [windowWidth]);

  return (
    <section className="image-gallery">
      <Container>
        <Row>
          <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
            <Col>
              <h3>Image Gallery</h3>
              {windowWidth < 768 ? (
                <Slider {...settings} className="btn-fix">
                  <div className="img-wrap img-zoom">
                    <Link to="/">
                      <img src={galleryImgMob} alt="gallery" />
                    </Link>
                  </div>
                  <div className="img-wrap img-zoom">
                    <Link to="/">
                      <img src={galleryImgMob} alt="gallery" />
                    </Link>
                  </div>
                  <div className="img-wrap img-zoom">
                    <Link to="/">
                      <img src={galleryImgMob} alt="gallery" />
                    </Link>
                  </div>
                  <div className="img-wrap img-zoom">
                    <Link to="/">
                      <img src={galleryImgMob} alt="gallery" />
                    </Link>
                  </div>
                  <div className="img-wrap img-zoom">
                    <Link to="/">
                      <img src={galleryImgMob} alt="gallery" />
                    </Link>
                  </div>
                  <div className="img-wrap img-zoom">
                    <Link to="/">
                      <img src={galleryImgMob} alt="gallery" />
                    </Link>
                  </div>
                </Slider>
              ) : (
                <div className="images-block">
                  {images
                    ? images.map((image, index) => (
                        <div key={index} className="img-wrap img-zoom">
                          <Link to="/">
                            <img src={image} alt="gallery" />
                          </Link>
                        </div>
                      ))
                    : null}
                </div>
              )}
            </Col>
          </AnimationOnScroll>
        </Row>
      </Container>
    </section>
  );
};

export default ImageGallery;
